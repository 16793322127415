
type RekaLogoProps = {
    svgWidth: number;
    color?: string;
}
export function RekaLogo({svgWidth,color}: RekaLogoProps){
    //width="98.469994mm"
    //height="27.827999mm"
    return (

        <svg
            width={svgWidth ?? 98.46994}
            viewBox="0 0 98.469993 27.827999"
            version="1.1"
            id="svg1"
           ><defs
     id="defs1" />
            <g
                id="g4"><path
       d="m 72.963292,27.827888 h -10.24293 l -4.43891,-9.182983 v 9.182983 h -9.65216 V 0.001833 h 9.65216 V 9.206441 L 62.720362,7e-5 l 10.24293,0.0018 -6.54566,13.923294 6.54566,13.902761"
       style={{fill: (color ?? "#ff8080"), fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeWidth: "0.0313916"}}
       id="path14" />
                <path
                    d="m 92.049792,8.8e-5 h -11.49288 l -6.42091,27.827818 h 9.06313 l 3.09733,-13.884135 3.11227,13.884135 h 9.06127 L 92.049792,8.8e-5"
                    style={{fill:(color ?? "#ff8080"), fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeWidth: "0.0287157"}}
                    id="path16"/>
                <g
                    id="g3"
                    transform="matrix(0.79155759,0,0,1,-38.430049,-31.259573)"
                    style={{fill:(color ?? "#ff8080")}}
                    ><g
         id="g1"
         transform="matrix(1,0,0,0.75696564,-7.1600881,3.2867564)"
         style={{fill:(color ?? "#ff8080"), fillOpacity: "1"}}
                ><path
           d="m 55.71,36.978718 13.923275,-0.02482 c 8.126421,-0.01437 13.914413,6.760285 13.926181,13.595208 0.01134,6.813823 -5.752841,13.637475 -13.879272,13.651467 l -13.934846,0.02399 -0.01352,-12.139815 12.426587,-0.02144 c 0.748279,-0.0013 1.499728,-0.717246 1.498443,-1.463725 -0.0013,-0.743579 -0.755282,-1.507388 -1.503561,-1.506102 l -12.429047,0.02144 -0.01351,-12.137064 -5.69e-4,8.51e-4 v 0"
           style={{fill:(color ?? "#ff8080"), fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeWidth: "0.0352778"}}
           id="path12-6" /><path
                    d="M 86.45192,73.716405 H 73.51591 l -5.60599,-9.182983 v 9.182983 H 55.72 V 45.89035 h 12.18992 v 9.204608 l 5.60599,-9.206371 6.049619,1.257299 -1.380259,12.667795 8.26665,13.902761"
                    style={{fill:(color ?? "#ff8080"), fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeWidth: "0.0352778"}}
                    id="path14-2"/></g></g>
                <g
                    id="g2"
                    transform="matrix(0.87414245,0,0,0.65356473,-170.31609,-2.370445)"
                    style={{fill:(color ?? "#ff8080"), fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeOpacity: "1"}}
                ><path
         d="m 249.11564,30.872824 -13.92329,8.4e-4 c -8.12645,0 -13.90276,-6.78423 -13.90276,-13.61916 0,-6.81383 5.77631,-13.6275598 13.90276,-13.6275598 h 13.93486 l -0.007,12.1398198 h -12.4266 c -0.74827,0 -1.50096,0.71466 -1.50096,1.46114 0,0.74359 0.75269,1.50869 1.50096,1.50869 h 12.42907 l -0.007,12.13707 5.6e-4,-8.4e-4 v 0"
         style={{fill:(color ?? "#ff8080"), fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeWidth: "0.0352778", strokeOpacity: "1"}}
         id="path12" />
                    <path
                        d="m 249.11564,46.204901 -13.92329,8.4e-4 c -8.12645,0 -13.90276,-6.78423 -13.90276,-13.61916 0,-6.81383 5.77631,-13.62756 13.90276,-13.62756 h 13.93486 l -0.007,12.13982 h -12.4266 c -0.74827,0 -1.50096,0.71466 -1.50096,1.46114 0,0.74359 0.75269,1.50869 1.50096,1.50869 h 12.42907 l -0.007,12.13707 5.6e-4,-8.4e-4 v 0"
                        style={{fill:(color ?? "#ff8080"), fillOpacity: "1", fillRule: "nonzero", stroke: "none", strokeWidth: "0.0352778", strokeOpacity: "1"}}
                        id="path12-62"/></g></g></svg>

    )
}
